<template>
  <section id="dashboard-ecommerce">
     <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.congratulations" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- Company Table Card -->
      
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-meetup :data="data.meetup" />
      </b-col>
      <b-col
        lg="8"
        md="6"
      >
       <analytics-support-tracker :data="data.supportTracker" />
      </b-col>
     
      <!--/ Developer Meetup Card -->

      <!-- Browser States Card -->
    
      <!--/ Browser States Card -->

      <!-- Goal Overview Card -->
      
      <!--/ Transaction Card -->
    </b-row>
     <b-row>
      <b-col cols="12">
        
        <property-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'

import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import PropertyList from '@/views/properties/property-list/PropertyLists.vue'
import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
export default {
  components: {
    BRow,
    BCol,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceMeetup,
    InvoiceList,
    PropertyList,
    AnalyticsSupportTracker

 
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
   
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        console.log('userData',userData);
        this.data.congratulations.name = userData.name
      })
       this.$http.get('/analytics/data')
      .then(response => { this.data.supportTracker = response.data.supportTracker })
       
      
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

</style>
